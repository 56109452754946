import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLeft, ArrowRight } from "../icons/"

const useStyles = makeStyles(theme => ({
    link: {
        backgroundColor: props => { return props.backgroundColor },
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        textDecoration: "none",
        color: "inherit",

        "& $icon": {
            transition: ".125s ease-out",
            margin: ".5em",
        },

        "&:hover $icon": {
            margin: "1em",
        }
    },
    icon: {
        fontSize: 16,
        
        [theme.breakpoints.up('md')]: {
            fontSize: 20
        }

    }
}));

const HomepageLink = ({backgroundColor, linkDirection = "left", url = "/"}) => {

    const classes = useStyles({backgroundColor})


    if (linkDirection === "left") {
        return (
            <TransitionLink 
                    to={url} 
                    entry={{
                        state: "fromLeft"
                    }}
                    exit={{
                        state: "exitLeft"
                    }}
            
                className={classes.link}>
                <ArrowLeft className={classes.icon} />
            </TransitionLink>
        )
    }

    return (
        <TransitionLink 
            to={url} 
            entry={{
                state: "fromRight"
            }}
            exit={{
                state: "exitLeft"
            }}

            className={classes.link}>
                <ArrowRight className={classes.icon} />
        </TransitionLink>
    )

    
    return (
        <TransitionLink to={url} className={classes.link}>
            {linkDirection === "left" && <div className={classes.icon}>&larr;</div> }
            {linkDirection === "right" && <div className={classes.icon}>&rarr;</div> }
        </TransitionLink>
    )
}


export default HomepageLink
