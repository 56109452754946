import React from 'react'
import HorizontalLink from "./HorizontalLink"
import HorizontalPage from "./HorizontalPage"

import { getPageUrl } from "../../lib/helpers"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    layout: {
        display: "flex",
        height: "100%"
    },
}));

const HorizontalPages = ({pages = [], id, ...props}) => {

    const classes = useStyles()

    let linkDirection = "left"

    return (
        <main className={classes.layout}>
            {pages && pages.map((item, index) => {

                if (!item) {
                    return false
                }

                const backgroundColor = item.color

                if (item.id === id) {
                    linkDirection = "right"
                    return (
                        <HorizontalPage {...props} backgroundColor={backgroundColor} key={index} />
                    )
                }

                const url = getPageUrl(item)

                return (
                    <HorizontalLink {...item} backgroundColor={backgroundColor} linkDirection={linkDirection} url={url} key={index} />
                )
            
            })}
        </main>
    )

}


HorizontalPages.defaultProps = {
}

export default HorizontalPages
