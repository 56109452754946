import React from 'react'
import Header from '../Header/Header'
import { TransitionState  } from 'gatsby-plugin-transition-link'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    page: {
        flexBasis: 0,
        flexGrow: 1,
        backgroundColor: props => { return props.backgroundColor },
        height: "100%",
        overflowY: "scroll",

        paddingLeft: "1em",
        paddingRight: "1em",

        "&[data-animation=fromLeft]": {
            transition: ".125s ease-out",
            marginLeft: "-100%"
        },

        "&[data-animation=fromRight]": {
            transition: ".125s ease-out",
            marginRight: "-100%"
        },
        
        "&[data-transition=entered]": {
            margin: 0
        }

    }
}));

const HorizontalPage = ({backgroundColor, children}) => {

    const classes = useStyles({backgroundColor})

    return (
        <div className={classes.page}>
            <div className={classes.grid}>
                <Header />
                {children}
            </div>
        </div>
    )

    return (
        <TransitionState>
        {({ transitionStatus, current, entry, exit, ...props }) => {
            console.log(transitionStatus)

            const animation = current && current.state


            return (
                <div className={classes.page}
                    data-animation={animation}
                    data-transition={transitionStatus}>
                    <Header />
                    {children}
                </div>
            )
        
        }}
        </TransitionState>
    
    )


}

export default HorizontalPage