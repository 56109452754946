import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const ArrowRight = (props) => {

    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <path d="M4.89999 10.9V9.09999H11.6L8.89999 6.39999H11.4L14.4 9.39999V10.6L11.4 13.6H8.89999L11.6 10.9H4.89999Z" fill="blacks"/>
            <path d="M1.5 18.5V1.5H18.5V18.5H1.5ZM0 20H20V0H0V20Z" fill="black"/>
        </SvgIcon>
    )

}


export default ArrowRight