import React from 'react'
import Link from 'gatsby-plugin-transition-link'
import Typography from "@material-ui/core/Typography"
import { ArrowRight } from "../icons"

import { makeStyles } from '@material-ui/core/styles';
import { getPageUrl } from "../../lib/helpers"

const useStyles = makeStyles(theme => ({
    article: {
        marginTop: "1.5em"
    },
    link: {
        display: "flex",
        alignItems: "center",

        borderTop: "1px solid",
        borderBottom: "1px solid",

        color: "inherit",
        textDecoration: "none",

        "&:hover $linkText": {
            marginTop: "1em",
            marginBottom: "1em",
        }

    },
    linkText: {
        transition: ".125s ease-out",
        marginTop: ".5em",
        marginBottom: ".5em",
    }
}));

const HomepageLinks = ({index, title, excerpt, heading, ...props}) => {

    const classes = useStyles()

    const url = getPageUrl(props)

    return (
        <section className={classes.article}>
            <Typography variant="h3">{index+1}. {title}</Typography>
            <Typography>{excerpt}</Typography>
            <Link className={classes.link} to={url}>
                <Typography className={classes.linkText} component="h2" variant="overline">{heading} &rarr;</Typography>
            </Link>
        </section>
    )
}

export default HomepageLinks