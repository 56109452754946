import React, { useState } from 'react'
import Contact from "../Contact/Contact"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    footer: {
        display: "flex",
        flexDirection: "column",
        marginTop: "2em",
        paddingBottom: "3em"        
    },
    
}));

const people = [
    {   
        url: "/inge",
        name: "Inge Fossland",
        phone: "+47 932 50 769",
        email: "inge@fosslandfoss.no"
    },
    {   
        url: "/thord",
        name: "Thord Veseth Foss",
        phone: "+47 924 32 825",
        email: "thord@fosslandfoss.no"
    }
]

const Footer = (props) => {

    const classes = useStyles()

    return (
        <footer className={classes.footer}>

            <Typography variant="h3">Vil du jobbe med oss?</Typography>
            <Typography variant="body2">Ta kontakt med en av oss og fortell oss om prosjektet ditt:</Typography>

            <Contact people={people} />
        </footer>
    )

}


Footer.defaultProps = {
    title: '',
    nodes: [],
    browseMoreHref: ''
}

export default Footer
