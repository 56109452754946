import React from 'react'
import ContactPerson from "./ContactPerson"
import ArticleGrid from "../Article/ArticleGrid"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    contact: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        "& > *": {
            flexBasis: 0,
            flexGrow: 1
        },

        "& > * + *": {
            marginTop: "1.5em"
        },

        [theme.breakpoints.up('md')]: {
            flexDirection: "row",

            "& > * + *": {
                marginTop: 0
            },
    
        }
        
    }
}));

const Contact = ({people = []}) => {

    const classes = useStyles()

    return (
        <section className={classes.contact}>
            { people.map((person, index) => <ContactPerson {...person} key={index} />)}
        </section>
    )

}


Contact.defaultProps = {
}

export default Contact
