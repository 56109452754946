import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import Container from '../containers/homepage'
import Homepage from '../components/Homepage/Homepage'

export const query = graphql`
    query HomePageQuery {
        page: sanityPage(_id: { regex: "/(drafts.|)home/" }) {
            _type
            id
            title
            content
            links {
                ... on SanityPage {
                  content
                  links {
                    ... on SanityArticle {
                      title
                      excerpt
                      heading
                      slug {
                        current
                      }
                    }
                  }
                }
              }
        }
    }
`

const IndexPage = props => {
    const { data, errors } = props
  
        if (errors) {
        return (
            <GraphQLErrorList errors={errors} />
        )
    }
  
    const page = data && data.page
    const sections = page && page.links

    if (!page) {
      throw new Error(
        'Missing homepage data. Open the studio at http://localhost:3333 and add "Homepage" page data and restart the development server.'
      )
    }

    return (
        <Container {...page}>
            <Homepage {...page} sections={sections} />
        </Container>
    )
}
  
export default IndexPage