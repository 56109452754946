import React from 'react'
import ArticleBase from "../Article/ArticleBase"
import ArticleBody from "../Article/ArticleBody"
import ArticleGrid from "../Article/ArticleGrid"
import ArticleContent from "../Article/ArticleContent"

import HomepageLink from "./HomepageLink"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    page: {
        flexBasis: 0,
        flexGrow: 1,
        backgroundColor: props => { return props.backgroundColor },
        height: "100%",
        overflowY: "scroll"
    },
}));

const HomepageSection = ({content, links}) => {

    return (
        <>
            <ArticleContent>
                {content}
            </ArticleContent>

            { links && links.map((link, index) => <HomepageLink {...link} index={index} key={index} />)}

        </>
    )
}

export default HomepageSection