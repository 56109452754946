import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const ArrowRight = (props) => {

    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <path d="M14.5 9.1V10.9H7.8L10.5 13.6H8L5 10.6V9.5L8 6.5H10.5L7.8 9.2L14.5 9.1Z" fill="black"/>
            <path d="M1.5 1.5H18.5V18.5H1.5V1.5ZM0 0V20H20V0H0Z" fill="black"/>
        </SvgIcon>
    )

}


export default ArrowRight