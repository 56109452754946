import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Theme from '../components/Theme/Theme'
import Layout from '../components/HorizontalPages'
import SEO from "./seo"

const query = graphql`
    query HomepageQuery {
        homepage: sanityPage(_id: { regex: "/(drafts.|)home/" }) {
            _type
            id
        }
        inge: sanityPerson(_id: { regex: "/(drafts.|)inge/" }) {
            _type
            id
            slug {
                current
            }
            color
            backgroundColor {
                hex
            }
        }
        thord: sanityPerson(_id: { regex: "/(drafts.|)thord/" }) {
            _type
            id
            slug {
                current
            }
            color
            backgroundColor {
                hex
            }
        }
    }
`

const HomepageContainer = ({children, ...props}) => {

    return (
        <Theme>
            <SEO title={props.title} /> 
            <StaticQuery
                query={query}
                render={data => {

                    const homepage = data && data.homepage
                    const inge = data && data.inge
                    const thord = data && data.thord

                    const pages = [
                        inge,
                        homepage,
                        thord,
                    ]
                                
                    return (
                        <Layout pages={pages} {...props}>
                            {children}
                        </Layout>
                    )

            }} />
        </Theme>
    )
}

export default HomepageContainer
