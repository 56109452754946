import React from 'react'
import ArticleBase from "../Article/ArticleBase"
import ArticleBody from "../Article/ArticleBody"
import ArticleGrid from "../Article/ArticleGrid"
import ArticleContent from "../Article/ArticleContent"
import ArticleFooter from "../Article/ArticleFooter"

import HomepageSection from "./HomepageSection"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    page: {
        flexBasis: 0,
        flexGrow: 1,
        backgroundColor: props => { return props.backgroundColor },
        height: "100%",
        overflowY: "scroll"
    },
}));

const Homepage = ({content, sections}) => {

    return (
        <ArticleBase>
            <ArticleGrid>
                <ArticleContent>
                    {content}
                </ArticleContent>
                { sections && sections.map((section, index) => <HomepageSection {...section} key={index} /> )}
                <ArticleFooter />
            </ArticleGrid>
        </ArticleBase>
    )
}

export default Homepage